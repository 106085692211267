// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { getFrequencyCapForListSaga, type ChangeIsFrequencyCap } from "../../actions/frequencyCapForJumps";
import {
  FREQUENCY_CAP_FOR_JUMPS_TABLE,
  CHANGE_IS_FREQUENCY_CAP,
  IS_CHANGE_IS_FREQUENCY_CAP_LOADING,
} from "../../helpers/constants";
import { bulkChangeIsFrequencyCap } from "../../services/frequencyCapForJumps";
import type { FrequencyCapForJumps } from "../../containers/FrequencyCapForJumps";

export function* makeFetch({
  payload: {
    items,
    isFrequencyCap,
  },
}: ChangeIsFrequencyCap): Saga<void> {
  try {
    yield put(setLoading(IS_CHANGE_IS_FREQUENCY_CAP_LOADING, true));

    const ids: number[] = items.map(({ jumpKey }: FrequencyCapForJumps): number => jumpKey);

    yield call(bulkChangeIsFrequencyCap, { ids, isFrequencyCap });
    yield put(addNotification({
      message: `Frequency cap ${isFrequencyCap ? "activated" : "deactivated"} for jumps: ${items.map(({ name }) => name).join(", ")}`,
      severity: "success",
    }));
    yield put(setTableSelection(FREQUENCY_CAP_FOR_JUMPS_TABLE, []));
    yield put(getFrequencyCapForListSaga());
  }
  catch (error) {
    yield put(addNotification({
      message: error.errorMessage || `Failed to ${isFrequencyCap ? "activate" : "deactivate"} Frequency Cap`,
      severity: "error",
    }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(IS_CHANGE_IS_FREQUENCY_CAP_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(CHANGE_IS_FREQUENCY_CAP, (makeFetch: Function));
}
