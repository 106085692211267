import { createSelector } from "reselect";
import { Map, List, fromJS } from "immutable";
import url from "url";
import createCachedSelector from "re-reselect";
import { operatorsLocalizationV2 } from "../../helpers/targetingUtils";

const typeMapper = {
  "MULTISELECT": "manualMultiple",
  "WITHOUT_VALUE": "WITHOUT_VALUE",
  "MULTISELECT_CUSTOM": "manualMultiple",
  "MULTISELECT_WITH_SUBVALUES": "withSubValuesMultiple",
  "SELECT": "simpleMultiple",
};

// need to remove this after refactor backend
function getMultiselectType(type, values) {
  if (type === "MULTISELECT" && values.length) {
    return "simpleMultiple";
  }
  return typeMapper[type];
}

function combineFunnelDescription(targeting) {
  if (["geoposition", "globalFrequency", "campaignFrequency"].includes(targeting.name)) {
    return targeting.value.map((v) => v.label).join("-");
  }
  if (
    targeting.value.length === 0
    && (targeting.operator === "exists" || targeting.operator === "absent")
  ) {
    return [targeting.name, targeting.operator, "true"].join("-");
  }
  if (
    targeting.value.length === 0
    && (targeting.operator === "equal" || targeting.operator === "not equal")
  ) {
    return targeting.name;
  }

  return targeting.value.join("-");
}

function getSameNames(sourceName, allNames) {
  return allNames
    .filter((name) => new RegExp(`^${sourceName}_clone_` + "[0-9]{1,3}$", "g").test(name));
}

function getMaximumIndex(sameNames) {
  const indexes = sameNames
    .map((sameName) => (+sameName.split("_").pop() || 0) + 1);
  return Math.max(1, ...indexes);
}

export const isTargetingRuleValuesAlreadyFetched = createSelector(
  (state, rule) => state.dictionaries.get("targetingValues").has(rule),
  (isFetched) => isFetched
);

export const getCurrentStepId = createSelector(
  (state) => state.steps,
  (steps) => steps.get("current")
);

export const getSteps = createSelector(
  (state) => state.steps,
  (steps) => steps.get("byId")
);

export const getCampaignInfo = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo
);

export const getCampaignInfoObject = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.remove("isAffiliateDataTypeDisabled").toObject()
);

export const getFunnelById = createSelector(
  (state, funnelId) => state.funnels.getIn(["byId", funnelId]),
  (funnel) => funnel
    || Map({
      name: "",
      rootTargeting: List([]),
      filterings: [],
      offers: [],
      actionOptionsParams: [],
    })
);

export const getSplitsAllIds = createSelector(
  (state) => state.splits,
  (splits) => splits.get("allIds")
);

export const getSplitNames = createSelector(
  (state) => state.dictionaries,
  (dictionaries) => dictionaries.get("splitNames")
);

export const getPlaceholdersList = createSelector(
  (state) => state.dictionaries,
  (dictionaries) => dictionaries.get("placeholders")
);

export const getPresetsList = createSelector(
  (state) => state.dictionaries,
  (dictionaries) => dictionaries.get("presets")
);

export const getFunnelsBySplitId = createSelector(
  (state, splitId) => state.splits.getIn(["byId", splitId, "funnels"], List()),
  (funnels) => funnels
);

export const getFunnelsCountBySplitId = (state, splitId) => getFunnelsBySplitId(state, splitId).size;

export const getFunnelsByIds = createSelector(
  (state, ids) => state.funnels.get("byId").filter((val, key) => ids.includes(key)),
  (funnels) => funnels.toJS()
);

export const getMaxFunnels = createSelector(
  (state) => state.dictionaries.get("maxFunnels"),
  (maxFunnels) => maxFunnels
);

export const getJsonCampaign = createSelector(
  (state) => state.dictionaries,
  (dictionaries) => dictionaries.get("jsonCampaign")
);

export const getFunnelsAllIds = createSelector(
  (state) => state.funnels,
  (funnels) => funnels.get("allIds")
);

export const getFunnelsAllNames = createSelector(
  (state) => state.funnels
    .get("byId")
    .toArray()
    .map(([, value]) => value.get("name")),
  (names) => names
);

export const getFunnelNamesByIds = createSelector(
  (state, ids) => state.funnels
    .get("byId")
    .filter((val, key) => ids.includes(key))
    .toArray()
    .map(([, value]) => value.get("name")),
  (names) => names
);

export const getNameBySplitId = createSelector(
  (state, splitId) => state.splits.getIn(["byId", splitId]),
  (split) => split.get("name")
);

export const isFunnelExists = createSelector(
  (state, funnelId) => state.funnels.get("byId").has(funnelId),
  (isExists) => isExists
);

export const getSplitsById = createSelector(
  (state) => state.splits,
  (splits) => splits.get("byId")
);

export const getActiveSplitsById = createSelector(
  (state) => state.splits,
  (splits) => splits.get("byId").filter((split) => split.get("isActive"))
);

export const getSplits = createSelector(
  (state) => state.splits,
  (splits) => splits
);

export const getFunnels = createSelector(
  (state) => state.funnels,
  (funnels) => funnels
);

export const getFunnelsObject = createSelector(
  (state) => state.funnels.get("byId"),
  (funnels) => funnels.toJS()
);

export const getFunnelsArrayBySplitId = createCachedSelector(
  getFunnelsBySplitId,
  (funnels) => (funnels ? funnels.toArray() : [])
)((state, splitId) => splitId);

export const getFunnelSearchResult = (state) => state.searchNavigation.get("result");
export const getFunnelSearchScrollToItem = (state) => state.searchNavigation.get("scrollToItem");
export const getFunnelSearchText = (state) => state.searchNavigation.get("text");

export const getTargetings = createSelector(
  (state) => state.targetings,
  (targetings) => targetings
);

export const getTargetingsAllIds = createSelector(
  (state) => state.targetings,
  (targetings) => targetings.get("allIds")
);

export const getActionOptionsParamsllIds = createSelector(
  (state) => state.actionOptionsParams,
  (actionOptionsParams) => actionOptionsParams.get("allIds")
);

export const getFilterings = createSelector(
  (state) => state.filterings,
  (filterings) => filterings
);

export const getFilteringNamesByFunnelId = createSelector(
  [
    (state, funnelId) => getFilteringsByFunnelId(state, funnelId).toJS(),
    (state) => state.filterings.get("byId"),
  ],
  (ids, collection) => collection.filter((val, key) => ids.includes(key)).valueSeq().toJS().map(({ name }) => name)
);

export const getOffers = createSelector(
  (state) => state.offers,
  (offers) => offers
);

export const getOffersByIds = createSelector(
  (state, ids) => state.offers.get("byId").filter((val, key) => ids.includes(key)),
  (offers) => offers
);

export const getFilteringsByIds = createSelector(
  (state, ids) => state.filterings.get("byId").filter((val, key) => ids.includes(key)),
  (offers) => offers
);

export const getFilteringsByIdsV2 = createSelector(
  (state, ids) => {
    const result = [];
    ids.forEach((id) => {
      result.push({ ...state.filterings.getIn(["byId", id]).toJS(), id });
    });
    return result;
  },
  (offers) => offers
);

export const getActionsOptionsParamsIdByFunnelId = createSelector(
  (state, funnelId) => state.funnels.getIn(["byId", funnelId, "actionOptionsParams"]),
  (actionOptionsParams) => actionOptionsParams.get(0)
);

export const getFilteringsAllIds = createSelector(
  (state) => state.filterings,
  (filterings) => filterings.get("allIds")
);

export const getOffersAllIds = createSelector(
  (state) => state.offers,
  (offers) => offers.get("allIds")
);

export const getIsFunnelModalOpen = createSelector(
  (state) => state.modals.get("funnel"),
  (funnel) => funnel.get("isOpen")
);

export const getIsSplitModalOpen = createSelector(
  (state) => state.modals.get("split"),
  (modal) => modal.get("isOpen")
);

export const getCurrentSplit = createSelector(
  (state) => state.modals.get("split"),
  (modal) => modal.get("currentSplit")
);

export const getIsOffersModalOpen = createSelector(
  (state) => state.modals.get("offers"),
  (offers) => offers.get("isOpen")
);

export const getIsConfirmDialogOpen = createSelector(
  (state) => state.confirmDialog.get("confirmation"),
  (confirmation) => confirmation.get("isOpen")
);

export const getCurrentConfirmDialogMessage = createSelector(
  (state) => state.confirmDialog.get("confirmation"),
  (confirmation) => confirmation.get("currentMessage")
);

export const getIsSmartlinkGeneralFiltersModalOpen = createSelector(
  (state) => state.modals.get("generalFilters"),
  (modal) => modal.get("isOpen")
);

export const getCampaignType = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.get("type")
);

export const getRequestLimitPerSec = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.get("requestLimitPerSec")
);

export const getCurrentFunnel = createSelector(
  (state) => state.modals.get("funnel"),
  (funnel) => funnel.get("currentFunnel")
);

export const getOfferById = createSelector(
  (state, offerId) => state.offers.getIn(["byId", offerId]),
  (offer) => offer
);

export const getFilteringById = createSelector(
  (state, id) => state.filterings.getIn(["byId", id]),
  (filtering) => filtering
);

export const getActionOptionsParamsById = createSelector(
  (state, id) => state.actionOptionsParams.getIn(["byId", id]),
  (actionOptionsParams) => actionOptionsParams
);

export const getCampaignName = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.get("name")
);

export const getAffiliateDataType = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.get("affiliateDataType")
);

export const getCampaignExternalName = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.get("externalName")
);

export const getCampaignProtocol = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.get("protocol")
);

export const getCampaignPath = createSelector(
  (state) => state.campaignInfo.get("path", "/tds"),
  (path) => path
);

export const getDomain = createSelector(
  (state) => state.campaignInfo.get("domain"),
  (domain) => domain
);

export const getShortUrlId = createSelector(
  (state) => state.campaignInfo.get("shortUrlId"),
  (shortUrlId) => shortUrlId
);

export const getShortUrlTime = createSelector(
  (state) => state.campaignInfo.get("shortUrlTime"),
  (shortUrlTime) => shortUrlTime
);

export const getSelectBy = createSelector(
  (state) => state.frequency,
  (frequency) => frequency.get("method")
);

export const getPeriod = createSelector(
  (state) => state.frequency,
  (frequency) => frequency.get("period")
);

export const getScope = createSelector(
  (state) => state.frequency,
  (frequency) => frequency.get("scope")
);

export const getDefaultSplit = createSelector(
  (state) => state.frequency,
  (frequency) => frequency.get("default")
);

export const getIsAddSplitDisabled = createSelector(
  [
    (state) => state.splits.get("allIds"),
    (state) => state.dictionaries.get("splitNames"),
  ],
  (allIds, splitNames) => allIds.size === splitNames.size
);

export const getIsSplitFunnelsValid = createSelector(
  (state, splitId) => state.errors.getIn(["splits", splitId]),
  (splitError) => splitError
);

export const getFrequency = createSelector(
  (state) => state.frequency,
  (frequency) => frequency
);

export const getFrequencyMethod = createSelector(
  (state) => state.frequency,
  (frequency) => frequency.get("method")
);

export const getManualTags = createSelector(
  (state) => state.tags,
  (tags) => tags.get("manualTags")
);

export const getNameByFunnelId = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "name"]);

export const generateCloneName = (state, funnelId) => {
  const sourceName = getNameByFunnelId(state, funnelId);
  const allNames = getFunnelsAllNames(state);
  const sameNames = getSameNames(sourceName, allNames);
  const index = getMaximumIndex(sameNames);
  return `${sourceName}_clone_${index}`;
};

export const getIsActiveByFunnelId = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "isActive"]);

export const getTargetingById = (state, targetingId) => state.targetings.get("byId").get(targetingId);

export const getAllTargetingsById = (state, targetingIds) => {
  const result = {};
  targetingIds.forEach((id) => {
    result[id] = getTargetingById(state, id);
  });

  return result;
};

export const getTargetingByIdV2 = createSelector(
  (state, targetingId) => state.targetings.get("byId").get(targetingId),
  (state) => state.dictionaries.get("targetingRules"),
  (targeting, dictionary) => {
    const targetingFromDic = dictionary
      .filter((rule) => rule.components.includes("targeting"))
      .find((i) => i.name.value === targeting.name);
    const label = targetingFromDic ? targetingFromDic.name.label : "";
    const targetingMaxValueCount = targetingFromDic ? targetingFromDic.targetingMaxValueCount : "";

    return {
      ...targeting,
      label,
      operator: {
        value: targeting.operator,
        name: operatorsLocalizationV2[targeting.operator],
      },
      targetingMaxValueCount,
    };
  }
);

export const getFilteringByIdV2 = createSelector(
  (state, id) => state.filterings.get("byId").get(id),
  (state) => state.dictionaries.get("targetingRules"),
  (filtering, dictionary) => {
    if (!filtering) {
      return {
        offer: {
          url: "",
          id: "",
        },
      };
    }
    const filter = filtering.toJS();
    const targetingFromDic = dictionary
      .filter((rule) => rule.components.includes("filtering"))
      .find((i) => i.name.value === filter.name);
    const filteringMaxValueCount = targetingFromDic ? targetingFromDic.filteringMaxValueCount : "";
    const label = targetingFromDic ? targetingFromDic.name.label : "";
    return {
      ...filter,
      label,
      operator: {
        value: "equal",
        name: "Equal",
      },
      filteringMaxValueCount,
    };
  }
);

export const getLoadingStatusForValues = (state, ruleName) => {
  const status = state.loading.get(`loadingTargetingValues-${ruleName}`) || false;
  const statusAll = state.loading.get("loadingTargetingValues") || false;

  return statusAll || status;
};

export const iterateByGroup = (state, targetingGroup, arr) => {
  const targetingsList = targetingGroup.targetings;
  // eslint-disable-next-line no-param-reassign
  let result = arr.concat(targetingsList);

  targetingsList.forEach((targetingId) => {
    const targetingRule = getTargetingById(state, targetingId);
    if (targetingRule.type !== "rule") {
      result = result.concat(iterateByGroup(state, targetingRule, result));
    }
  });
  return result;
};

export const getRootTargetingByFunnelId = (state, funnelId) => getFunnelById(state, funnelId)
  .get("rootTargeting")
  .get(0);

export const getTargetingIdsByFunnelId = (state, funnelId) => {
  const rootTargetingId = getRootTargetingByFunnelId(state, funnelId);
  const rootTargeting = getTargetingById(state, rootTargetingId);
  const result = [];
  if (!rootTargetingId || !rootTargeting) {
    return result;
  }
  return [rootTargetingId, ...iterateByGroup(state, rootTargeting, result)];
};

export const getTargetingCountByFunnelId = createCachedSelector(
  (state) => state.funnels,
  (state) => state.targetings,
  (state, funnelId) => funnelId,
  (funnels, targetings, funnelId) => getTargetingIdsByFunnelId({ funnels, targetings }, funnelId).length - 1
)((state, funnelId) => funnelId);

export const getTargetingWithValue = (state, targetingId) => {
  const targeting = {
    ...getTargetingById(state, targetingId),
  };
  const targetingValues = state.dictionaries.get("targetingValues").get(targeting.name) || [];

  if (targeting.type === "rule") {
    targeting.value = targeting.value.map((item) => {
      const value = targetingValues.find((element) => element.value === item);
      return value ? value.label : item;
    });
  }

  return targeting;
};

export const getFunnelDescriptionByFunnelId = createCachedSelector(
  (state) => state.funnels,
  (state) => state.targetings,
  (state) => state.dictionaries,
  (state, funnelId) => funnelId,
  (funnels, targetings, dictionaries, funnelId) => {
    const state = { funnels, targetings, dictionaries };
    const result = [];
    const targetingIds = getTargetingIdsByFunnelId(state, funnelId);

    targetingIds.forEach((targetingId) => {
      const targeting = getTargetingWithValue(state, targetingId);
      if (targeting.type === "rule") {
        result.push(combineFunnelDescription(targeting));
      }
    });

    return result.join("-");
  }
)((state, funnelId) => funnelId);

export const getAllTargetingRuleNames = (state, rootTargetingGroup) => {
  let result = List([]);
  rootTargetingGroup.targetings.forEach((id) => {
    const rule = state.targetings.getIn(["byId", id]);
    if (rule.type === "rule") {
      result = result.push(rule.name);
    }
  });
  return result;
};

export const getAllTargetingRuleNamesV2 = createSelector(
  (state, rootTargetingGroup) => rootTargetingGroup.targetings.map((id) => state.targetings.getIn(["byId", id])),
  (targetings) => targetings.filter((targeting) => targeting.type === "rule").map((targeting) => targeting.name)
);

export const getAllFilteringRuleNamesV2 = createSelector(
  (state, ids) => ids.map((id) => state.filterings.getIn(["byId", id])),
  (filterings) => {
    const listOfFiltering = filterings.filter((filtering) => filtering.get("type") === "rule") || [];
    return listOfFiltering.map((filtering) => filtering.get("name"));
  }
);

export const getTargetingRulesList = (state) => state.dictionaries.get("targetingRules");

export const getTargetingRulesListV2 = createSelector(
  [
    (state) => state.dictionaries.get("targetingRules"),
    (state, groupId) => {
      const group = getTargetingById(state, groupId);
      return getAllTargetingRuleNamesV2(state, group);
    },
  ],
  (dictionaries, rulesList) => {
    const rules = dictionaries.toArray();
    const result = [];

    rules.forEach((ruleFromDic) => {
      const maxCount = ruleFromDic.maxTargetingRulesCount;
      if (!ruleFromDic.components.includes("targeting")) {
        return;
      }
      const setupedCounter = rulesList.filter((i) => i === ruleFromDic.name.value).length;
      if (setupedCounter >= maxCount) {
        result.push({ ...ruleFromDic, disabled: true });
        return;
      }
      result.push(ruleFromDic);
    });
    return result;
  }
);

export const getFilteringRulesListV2 = createSelector(
  [
    (state) => state.dictionaries,
    (state, funnelId) => {
      const targetingList = getFilteringsByFunnelId(state, funnelId);
      return getAllFilteringRuleNamesV2(state, targetingList);
    },
  ],
  (dictionaries, rulesList) => {
    const rules = dictionaries.get("targetingRules").toArray();
    const result = [];
    const isRuleWithValue: (rule) => boolean = (rule = {}): boolean => rule.type !== "WITHOUT_VALUE";
    const getRuleGroup: (rule) => string = (rule = {}) => {
      if (rule.group === "query") return "Query";
      return isRuleWithValue(rule) ? "With value" : "Without value";
    };

    rules.forEach((ruleFromDic) => {
      if (!ruleFromDic.components.includes("filtering")) {
        return;
      }

      const group = getRuleGroup(ruleFromDic);
      const maxCount = ruleFromDic.maxFilteringRulesCount;
      const setupedCounter = rulesList.filter((i) => i === ruleFromDic.name.value).size;
      if (setupedCounter >= maxCount) {
        result.push({ ...ruleFromDic, disabled: true, group });
        return;
      }
      result.push({ ...ruleFromDic, group });
    });
    
    return result;
  }
);

export const getTargetingValues = createSelector(
  (state) => state.dictionaries,
  (dictionaries) => dictionaries.get("targetingValues")
);

export const getTargetingValue = createSelector(
  [
    (state) => state.dictionaries.get("targetingValues"),
    (state, ruleName) => state.dictionaries.get("targetingRules")
      .filter((rule) => rule.components.includes("targeting"))
      .find((rule) => rule.name.value === ruleName),
  ], (targetingValues, targetingRule) => {
    if (!targetingRule) {
      return {
        list: [],
        multiSelectType: "simpleMultiple",
      };
    }
    const list = targetingValues.get(targetingRule.name.value) || [];
    return {
      list,
      multiSelectType: getMultiselectType(targetingRule.type, list),
    };
  }
);

export const getFilteringValue = createSelector(
  [
    (state) => state.dictionaries.get("targetingValues"),
    (state, ruleName) => state.dictionaries.get("targetingRules")
      .filter((rule) => rule.components.includes("filtering"))
      .find((rule) => rule.name.value === ruleName),
  ], (targetingValues, targetingRule) => {
    if (!targetingRule) {
      return {
        list: [],
        multiSelectType: "simpleMultiple",
      };
    }
    const list = targetingValues.get(targetingRule.name.value) || [];
    return {
      list,
      multiSelectType: getMultiselectType(targetingRule.type, list),
    };
  }
);

export const getTargetingOperators = (state, ruleName) => {
  const targetingRule = state.dictionaries.get("targetingRules").find((rule) => rule.name.value === ruleName);

  if (!targetingRule) {
    return [];
  }

  return targetingRule.operators.map((operator) => ({ value: operator, name: operatorsLocalizationV2[operator] }));
};

export const getFilteringsByFunnelId = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "filterings"]);
export const getActionOptionsParamsByFunnelId = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "actionOptionsParams"]);

export const getOffersByFunnelId = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "offers"]);

export const getIsActiveBySplitId = (state, id) => state.splits.getIn(["byId", id, "isActive"]);

export const getAllIds = (state) => state.splits.get("allIds");

export const getSplitsCount = (state) => state.splits.get("allIds").size;

export const getErrors = (state) => state.errors;

export const getTargetingErrorById = (state, id) => {
  const result = state.errors.getIn(["targetings", id], Map());
  return result.toJS();
};

export const getFilteringErrorById = (state, id) => {
  const result = state.errors.getIn(["filterings", id], Map());
  return result.toJS();
};

export const getPlaceholders = (state) => state.placeholders;

export const getState = createSelector(
  (state) => state,
  (state) => state
);

export const getFallbackOffers = (state) => state.fallback.get("offers");

export const getNonTargetOffers = (state) => state.nonTarget.get("offers");

export const isSplitDefault = (state, id) => state.splits.getIn(["byId", id, "name"]) === state.frequency.get("default");

export const getAllActiveSplitsNames = (state) => state.splits
  .get("byId")
  .filter((x) => x.get("isActive"))
  .toArray()
  .map(([, value]) => value.get("name"));

export const getSplitById = (state, id) => state.splits.getIn(["byId", id])
  || fromJS({
    name: "",
    isActive: true,
    funnels: [],
    weight: 0,
    frequency: [],
  });

export const getCampaignId = createSelector(
  (state) => state.campaignInfo.get("id"),
  (id) => id
);

export const getProtocol = createSelector(
  (state) => state.campaignInfo.get("protocol"),
  (protocol) => protocol
);

const sourcesListFromTargeting = [
  "utm_campaign",
  "utm_content",
  "utm_source",
  "utm_term",
  "utm_medium",
  "data2",
  "data3",
  "utm_sub",
];

export const getFunnelUrl = createCachedSelector(
  [
    getCampaignId,
    getDomain,
    getProtocol,
    getPlaceholders,
    getTargetings,
    getFunnels,
    getCampaignPath,
    (state, funnelId) => funnelId,
  ],
  // eslint-disable-next-line max-params
  (campaignId, domain, protocol, placeholders, targetings, funnels, path, funnelId) => {
    const tdsParams = {
      tds_campaign: campaignId,
      tdsId: `${campaignId}_r`,
    };

    const rootTargetingId = funnels.getIn([
      "byId",
      funnelId,
      "rootTargeting",
      0,
    ]);

    const rootTargetingRulesId = targetings.getIn([
      "byId",
      rootTargetingId,
      "targetings",
    ]);

    if (!rootTargetingId || !rootTargetingRulesId) {
      return url.format({
        protocol,
        hostname: domain,
        pathname: `${path || "/tds"}`,
        query: {
          ...tdsParams,
          ...placeholders.toJS(),
        },
      });
    }

    const targetingRules = targetings
      .get("byId")
      .filter(
        (value, key) => rootTargetingRulesId.includes(key)
          && sourcesListFromTargeting.includes(value.name)
          && value.operator.toLowerCase() === "equal"
      )
      .reduce((acc, value) => {
        let [valueForUrl] = value.value;
        if (!valueForUrl) valueForUrl = "undefined";
        return { ...acc, [value.name]: valueForUrl };
      }, {});

    return decodeURIComponent(
      url.format({
        protocol,
        hostname: domain,
        pathname: `${path || "/tds"}`,
        query: {
          ...tdsParams,
          ...placeholders.toJS(),
          ...targetingRules,
        },
      })
    );
  }
)((state, funnelId) => funnelId || "");

export const getFunnelIsEditing = createCachedSelector(
  getFunnels,
  getFunnelsBySplitId,
  (state, splitId, funnelId) => funnelId,
  (funnels, funnelsIds, funnelId) => funnelsIds.size > 1 && funnelsIds.some((id: string): boolean => funnelId !== id && funnels.getIn(["byId", id, "isActive"]))
)((state, splitId, funnelId) => `${splitId}-${funnelId}`);

export const getFunnelName = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "name"]);
export const getFunnelHits = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "hits"]);
export const getFunnelIsActive = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "isActive"]);
export const getFunnelOffersCount = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "offers"], List()).size;
export const getFunnelFilteringCount = (state, funnelId) => state.funnels.getIn(["byId", funnelId, "filterings"], List()).size;

export const getCampaignData = createSelector(
  [
    getCampaignInfoObject,
    (state) => state.placeholders.toJS(),
    (state) => state.offers.toJS(),
    (state) => state.targetings.toJS(),
    (state) => state.filterings.toJS(),
    (state) => state.frequency.toJS(),
    (state) => state.nonTarget.toJS(),
    (state) => state.fallback.toJS(),
    (state) => state.splits.toJS(),
    (state) => state.funnels.toJS(),
    (state) => state.generalFilters.toJS(),
    (state) => state.tags.toJS(),
    (state) => state.actionOptionsParams.toJS(),
  ],
  (
    campaignInfo,
    placeholders,
    offers,
    targetings,
    filterings,
    frequency,
    nonTarget,
    fallback,
    splits,
    funnels,
    generalFilters,
    tags,
    actionOptionsParams
    // eslint-disable-next-line max-params
  ) => ({
    campaignInfo,
    placeholders,
    offers,
    targetings,
    filterings,
    frequency,
    nonTarget,
    fallback,
    splits,
    funnels,
    generalFilters,
    tags,
    actionOptionsParams,
  })
);

export const funnelTemplateListModalIsOpen:boolean = createSelector(
  (state) => state.modals.getIn(["funnelTemplate", "isOpen"]),
  (modalOpen) => modalOpen
);

export const getIsFunnelTemplateListLoading = createSelector(
  (state) => state.loading.get("funnelsTemplateList"),
  (isLoading) => isLoading
);

export const getIsCampaignSaveLoading = createSelector(
  (state) => state.loading.get("loadingSaveCampaign"),
  (isLoading) => isLoading
);
export const getIsCampaignJsonLoading = createSelector(
  (state) => state.loading.get("loadingCampaignJson"),
  (isLoading) => isLoading
);

export const getIsGeneralFiltersLoading = createSelector(
  (state) => state.loading.get("generalFilters"),
  (isLoading) => isLoading
);
export const getIsTargetingListLoading = createSelector(
  (state) => state.loading.get("loadingTargetingRules"),
  (isLoading) => isLoading
);

export const getFunnelsTemplateList = createSelector(
  (state) => state.dictionaries.get("funnelTemplates"),
  (data) => data
);

export const getCurrentSelectedFunnelTemplateSplit = createSelector(
  (state) => state.modals.getIn(["funnelTemplate", "currentSplit"]),
  (split) => split
);

export const getCurrentSelectedFunnelTemplateId = createSelector(
  (state) => state.modals.getIn(["funnelTemplate", "currentTemplate"]),
  (template) => template
);

export const getFunnelsTemplateListCount = createSelector(
  (state) => state.modals.getIn(["funnelTemplate", "count"]),
  (count) => count
);

export const getFunnelTemplates = createSelector(
  (state) => state.dictionaries,
  (dictionaries) => dictionaries.get("funnelTemplateValues")
);

export const getFunnelTemplateByTemplateId = createSelector(
  (state, templateId) => state.dictionaries.getIn(["funnelTemplateValues", templateId]),
  (template) => template
);

export const getIsModalContentDisabled = createSelector(
  (state) => state.modals.get("funnel"),
  (funnel) => funnel.get("isContentDisabled")
);

export const canChangeRule = createSelector(
  [
    (state, rootId) => state.targetings.get("byId").get(rootId),
    (state, rootId, id) => id,
  ],
  (root, targetingId) => {
    const ruleIndex = root.targetings.findIndex((el) => el === targetingId);
    return ruleIndex !== 0;
  }
);
